import * as React from 'react';
import * as ReactDOM from 'react-dom';
import BookmarkButton from './components/bookmark-button/BookmarkButton';
import { IBookmarkButtonProps } from './components/types';

if (window && window.shell) {
    window.shell.subscribeTo('ESCID.ESPP.Bookmark.CreateClientSideAddBtn',
        (payload) => {
            const placeHolder: HTMLElement = document.getElementById(payload.containerId);

            if (placeHolder) {
                renderApp(payload, placeHolder);
            }
        },
        'ESCID.ESPP.Bookmark.CreateClientSideAddBtn');
}

function renderApp(payload: IBookmarkButtonProps, placeHolder: HTMLElement) {
    ReactDOM.render(
        <div className='pdp-invisible_container bookmark-add-button'>
            <BookmarkButton {...payload} />
        </div>,
        placeHolder
    );
}
